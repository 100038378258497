import Card from "./Card";
function Services() {
  return (
    <div className="container services">
      <h2 className="main-title text-center">Our Goals</h2>
      <div className="card-cover">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-4 mb-2">
              <Card
                title="Innovative Solutions"
                img="card1.png"
                text="Continuously develop and introduce cutting-edge chemical and polymer products to meet evolving industry needs and ensure superior performance."
              />
            </div>
            <div className="col-md-4 mb-2">
              <Card
                title="Customer Satisfaction"
                img="card2.png"
                text="Provide exceptional services & tailored solutions to exceed customer expectations and build long lasting trustworthy partnerships"
              />
            </div>
            <div className="col-md-4 mb-2">
              <Card
                title="Reliability & Excellence"
                img="card3.png"
                text="Maintain the highest standards of quality and consistency in our products, ensuring dependable results for our clients in every application"
              />
            </div>
            <div className="col-md-4 mb-2">
              <Card
                title="Cost-Effective Offerings"
                img="card4.png"
                text="Provide high-quality products at competitive prices, ensuring excellent value and affordability for our customers."
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Services;
