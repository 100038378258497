import { Link } from "react-scroll";


function Header() {
    return (
      <header>
        <video src="./Intro.mp4" autoPlay muted></video>
        <img src="./img/Logo.png" alt="Dugar Polychem" className="logo" />
        <h1 className="header">Dugar Polychem</h1>
        <h2 className="header-2">We are Dealers, Distributors & Importers of PVC Resin, Plasticisizers & Additives.</h2>
        <div className="row">
          <button className="btn" style={{ cursor: "pointer" }}>
          <Link
                activeClass="active"
                spy={true}
                smooth={true}
                duration={1000}
                to="about"
              >
                Learn More About Us
              </Link>
          </button>
        </div>
  
        <div className="headerbg"></div>
      </header>
    );
  }
  export default Header;
  