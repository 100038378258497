import { Link, animateScroll as scroll } from 'react-scroll'

function Footer() {


  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="side1">
            <div className="row">
              <div className="col-md-3">
                <h1 className="logo">Dugar Polychem</h1>
                <p className="footer-text">
                  Inspiring Growth
        
                </p>
              </div>
            </div>
          </div>
          <div className="side2">
            <div className="row">
              <div className="col-md-3">
                <p className="footer-title">Contact</p>
                <ul>
                  <li>
                    <Link to="#" >admin@dugarpolychem.com</Link>
                  </li>
                  <li>
                    <Link to="#" > 102 Gupta Complex, Inderlok <br></br>Delhi 110035</Link>
                  </li>
                  <li>
                    <Link to="#" >+91 9891380449</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button onClick={() => scroll.scrollToTop(2500)} src="" className="gotop"><i className="fas fa-level-up-alt"></i></button>

    </footer>
  );
}
export default Footer;
