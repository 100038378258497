function About() {
  return (
    <>
      <div
        style={{ marginTop: "15rem", width: "100%", height: "10px" }}
        className="about-scroll"
      ></div>

      <div className="container about">
        <div className="row">
          <div className="col-md-6 text-center">
            <img alt="about" src="./img/about.png" className="img-fluid" />
          </div>
          <div className="col-md-6">
            <h2 className="main-title about-h2">About Us</h2>
            <p className="main-p">
              {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur
                quam purus, ullamcorper id risus eu, consectetur consectetur
                purus. Etiam sagittis in eros ac sollicitudin. */}
              Dugar Polychem, with over two decades of industry expertise, is a
              leading provider of high- quality chemicals and polymers such as
              PVC Resin, PVC Paste Resin, DOP, DBP, CPW, DINP, DOTP, DOA, TOTM,
              Blowing Agent, TiO2 and countless others. Our commitment to
              innovation, sustainability, and customer satisfaction ensures
              unparalleled performance and reliability, making us a trusted
              partner for all your industrial needs
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
export default About;
