import { Gallery } from "react-grid-gallery";

const images = [
  {
    src: "./img/gallery/img1.png",
  },
  {
    src: "./img/gallery/img2.png",
  },
  {
    src: "./img/gallery/img3.png",
  },
  {
    src: "./img/gallery/img4.png",
  },
  {
    src: "./img/gallery/img5.png",
  },
  {
    src: "./img/gallery/img6.png",
  },
  {
    src: "./img/gallery/img7.png",
  },
  {
    src: "./img/gallery/img8.png",
  },
  {
    src: "./img/gallery/img9.png",
  },
  {
    src: "./img/gallery/img10.png",
  },
  {
    src: "./img/gallery/img11.png",
  },
  {
    src: "./img/gallery/img12.png",
  },
];

function GalleryComponent() {
  return (
    <>
      <div
        style={{ marginTop: "15rem", width: "100%", height: "10px" }}
        className="gallery-scroll"
      ></div>

      <div className="container services">
        <div className="row">
          <h2 className="main-title text-center col-md-4 mb-2 col-md-12">Portfolio</h2>
        </div>
        <Gallery images={images} enableImageSelection={false} />
      </div>
    </>
  );
}
export default GalleryComponent;
